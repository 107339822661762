.keyboard_button_default {
    font-size: 24px;
    height: 80px !important;
}

.keyboard_button_action {
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    background: #7ac968 !important;
    height: 80px !important;
}

.keyboard_button_action_disabled {
    height: 80px !important;
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    border-color: #d9d9d9;
    background: rgba(0, 0, 0, 0.04) !important;
}